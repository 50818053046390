import React, { FC, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import { IProductCardData } from 'components/ProductCard';
import TitleWithImage from 'common/TitleWithImage';
import BreadCrumbs from 'common/BreadCrumbs';
import ProductsList from 'components/ProductsList';
import AlgoliaQuizResult from 'components/AlgoliaQuizResult';
import DisclaimerMessage from 'common/DisclaimerMessage';
import ProductQuizResultMessage, {
  ResultMessageRefinementTaxonomy,
} from 'components/ProductQuizResultMessage';
import TargetingSection from 'components/TargetingSection';
import BuyPromoBanner from 'components/BuyPromoBanner';

import { DefaultSearchState, IAlgoliaSearchStateData } from 'utils/algoliaFilters';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { schemaService } from 'utils/schemaService';

import { IPropsProductQuizPage } from './models';

import './ProductQuizPage.scss';

const ProductQuizPage: FC<IPropsProductQuizPage> = ({
  data: { pageData, defaultProducts },
  pageContext: { breadcrumbs, lang, link },
  location,
}) => {
  const [searchState, setSearchState] = useState<IAlgoliaSearchStateData>(DefaultSearchState);
  const [isCompletedProductsFinder, setCompletedProductsFinder] = useState<boolean>(false);
  const [savedItems, setSavedItems] = useState<IProductCardData[]>([]);

  const { isMobile, isTablet } = useScreenRecognition();
  const isSmallDevice = isMobile || isTablet;
  const { seo, defaultCompositions, targetingSection } = pageData;

  const callbackHandleQuizVisibility = useCallback((isOpen: boolean) => {
    if (isOpen) {
      setCompletedProductsFinder(false);
    }
  }, []);

  const handleCompleteProductsFinder = useCallback((isCompleted: boolean) => {
    setCompletedProductsFinder(isCompleted);
  }, []);

  useEffect(() => {
    if (!location.search) {
      setSearchState(DefaultSearchState);
      setSavedItems(defaultProducts.nodes);
    }
  }, [location.search]);

  const {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  } = useAlgoliaResponse(defaultProducts.nodes);

  // Saving current set of products
  useEffect(() => {
    // initial set
    if (isHitsResponseActivated && !savedItems.length) {
      setSavedItems(itemsToRender);
    }

    // after set's update
    if (isCompletedProductsFinder && savedItems.length) {
      setSavedItems(itemsToRender);
    }
  }, [isCompletedProductsFinder, isHitsResponseActivated]);

  return (
    <Layout
      seo={seo}
      defaultCompositions={defaultCompositions}
      additionalStructuredSchemas={[schemaService.schemaTypes.BreadcrumbList]}
      dynamicStructuredSchemaValues={{ pageUrl: link, breadcrumbs }}
    >
      <div data-test="ProductsQuizPage" className="product-quiz-page">
        <Container>
          <BreadCrumbs breadcrumbs={breadcrumbs} />
        </Container>

        <TitleWithImage
          title={pageData.title}
          titleImage={pageData.titleImage}
          titleImageAlt={pageData.titleImageAlt}
        />
        <AlgoliaQuizResult
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-productQuiz`}
          productQuizData={defaultCompositions.productQuiz}
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
          lang={lang}
          searchState={searchState}
          setSearchState={setSearchState}
        />
        {isHitsResponseActivated ? (
          <ProductsList
            products={isCompletedProductsFinder ? itemsToRender : savedItems}
            sorryMessage={pageData.sorryMessage}
            showMoreCtaButton={pageData.showMoreCtaButton}
            limitOnPageDesktop={pageData.limitOnPageDesktop}
            limitOnPageMobile={pageData.limitOnPageMobile}
            isSmallDevice={Boolean(isSmallDevice)}
          />
        ) : (
          <div className="product-quiz-page__loading" />
        )}

        <Container>
          {pageData?.quizResultMessages &&
          searchState?.refinementList?.[ResultMessageRefinementTaxonomy]?.[0] ? (
            <ProductQuizResultMessage
              currentSymptomId={searchState.refinementList[ResultMessageRefinementTaxonomy][0]}
              quizResultMessages={pageData.quizResultMessages}
            />
          ) : null}

          <DisclaimerMessage
            content={pageData.warningMessage}
            isDesktop={false}
            className="product-quiz-page__disclaimer-message"
          />
        </Container>

        {pageData.buyPromoBanner?.length ? (
          <BuyPromoBanner
            buyPromoBanner={pageData.buyPromoBanner}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            dir={defaultCompositions.siteSettings.dir[0]}
          />
        ) : null}

        {targetingSection?.[0] ? (
          <TargetingSection
            targetingSection={targetingSection}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            productQuizData={defaultCompositions.productQuiz}
            lang={lang}
            setParentSearchState={setSearchState}
            callbackHandleQuizVisibility={callbackHandleQuizVisibility}
            handleParentAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleCompleteProductsFinder={handleCompleteProductsFinder}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!, $lang: String!, $productsLinks: [String]) {
    pageData: productQuiz(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      titleImage {
        ...FragmentGatsbyImage
      }
      titleImageAlt
      sorryMessage
      showMoreCtaButton {
        ...FragmentButton
      }
      limitOnPageDesktop
      limitOnPageMobile
      quizResultMessages {
        ...FragmentQuizResultList
      }
      warningMessage
      targetingSection {
        ...FragmentTargetingSection
      }
      buyPromoBanner {
        ...FragmentBuyPromoBanner
      }
    }
    defaultProducts: allProduct(filter: { link: { in: $productsLinks }, lang: { eq: $lang } }) {
      nodes {
        ...FragmentProductCard
      }
    }
  }
`;

export default ProductQuizPage;
